<template>
    <div class="opinions">
        <p class="title">CO MÓWIĄ O NAS KIEROWCY</p>
        <p class="opinion">Will be soon</p>
    </div>
</template>

<style lang="scss" scoped>
    .opinions {
        height: 50vh;
        display: flex;
        align-items: center;
        overflow: hidden;
        flex-direction: column;
    }
    .title {
        color: $primary-color;
        font-family: 'Roboto-Light', 'sans-serif';
        font-size: 42px;
        text-align: center;
        margin-top: 100px
    }
    .opinion {
        font-size: 32px;
        color: white;
        font-family: 'Roboto-Light', sans-serif;
    }
</style>