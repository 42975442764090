<template>
    <div class="cabbie-offer">
        <div class="text-container">
            <p class="title">POZNAJ NASZĄ OFERTĘ</p>
            <p class="description-title">BRAK PROWIZJI OD TWOICH ZAROBKÓW</p>
            <p class="description">Oferujemy kierowcom pełne zarobki bez ukrytych prowizji.</p>
            <div class="strip"></div>
            <p class="description-title">TYGODNIOWE ROZLICZENIA</p>
            <p class="description">Nasz system zapewnia przejrzystość i terminowe wypłaty.</p>
            <div class="strip"></div>
            <p class="description-title">WSPÓLNE PARTNERSTWO</p>
            <p class="description">Jesteśmy zawsze otwarci na współpracę i wsparcie dla kierowców.</p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .cabbie-offer {
        background-color: $primary-color;
        height: auto;
        padding: 20px;
    }

    .text-container {
        text-align: center;
    }

    .title {
        font-size: 36px;
        font-family: 'Roboto-Light', sans-serif;
    }

    .description-title {
        font-size:  24px;
        font-family: 'Roboto-Light', sans-serif;
    }

    .description {
        font-size: 20px;
        font-family: 'Roboto-Extra-Light', sans-serif;
    }

    .strip {
        height: 0.5px;
        border-radius: 25px;
        width: 60%;
        background-color: $tertiary-color;
        margin: 0 auto;
    }
</style>